import { RenderComponent } from '@vue-storefront/core'
import { Logger } from '@vue-storefront/core'
import { Image, MappedImage } from '../types'

const extractNestedImage = (item) => item.image

export const getImageObject = ({ defaultHost, endpoint, name }: Image): MappedImage => ({
  url: `https://${defaultHost}/i/${endpoint}/${name}`,
  alt: name,
})

const filterProps = ['_meta']

export const extractComponents = (items: any[]): RenderComponent[] => {
  if (!items || !items.length) {
    Logger.error('No content items provided for render')
    return []
  }
  return items.map((item) => {
    return {
      componentName: item.component,
      props: ((data) => {
        filterProps.forEach((prop) => delete data[prop])
        if (data.image) {
          data.image = getImageObject(data.image)
        }
        if (data.images && Array.isArray(data.images)) {
          data.images = data.images.map(extractNestedImage).map(getImageObject)
        }
        if (data.items && Array.isArray(data.items)) {
          data.items = data.items.map((subItem) => {
            subItem.image && (subItem.image = getImageObject(subItem.image))
            return subItem
          })
        }
        if (data.component) {
          delete data.component
        }
        return data
      })(JSON.parse(JSON.stringify(item))),
    }
  })
}
